<template>
  <div class="lead-purpose">
    {{ purpose }}
    <slot></slot>
  </div>
</template>

<script>
export default {
  components: {},
  mixins: [],
  props: {
    purpose: {
      type: String
    }
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
.lead-purpose {
  text-transform: uppercase;
  background: $color-main;
  color: white;
  border-radius: 50em;
  padding: 4px 8px;
  text-align: center;
  font-weight: bold;
}
</style>
